<template>
<v-row>
    <v-col>
        <s-toolbar label="Soporte Usuarios" />
        <v-card :loading="loading" outlined>

            <v-card-title primary-title>
                <h3>Soporte EXACTUS </h3>
                <span style="margin-left: 10px; margin-right:10px">Antes de realizar alguna acción, seleccione una empresa:
                </span>
                <s-select-definition :def="1142" return-object v-model="cboBusiness"></s-select-definition>
            </v-card-title>
            <v-divider horizontal></v-divider>

            <v-card-text v-if="$fun.isAdmin() || !$fun.isAdmin()">
                <v-row>
                    <v-col cols="12" lg="12" md="12">
                        <p>Borrar asientos del dia - Entorno Pruebas</p>
                        <v-btn elevation="0" small label="Borrar Asientos" @click="deleteAsientos()">Borrar asientos</v-btn>
                    </v-col>
                </v-row>

            </v-card-text>

            <v-card-text v-if="$fun.isAdmin()">

                <v-row>
                    <v-col cols="12" lg="4" md="4">
                        <h2>Consulta Tipo Cambio [SUNAT]</h2>
                        <p class="mt-2">
                            Tipos de cambio de página SUNAT.
                            <!-- <v-icon @click="exportExcel" style="margin-left: 5px;" color="green darken-1">far fa-file-excel</v-icon> -->
                            <v-btn color="success" class="mt-0" icon x-small @click="exportExcel">
                                <v-icon>far fa-file-excel</v-icon>
                            </v-btn>
                        </p>
                        <v-row>
                            <v-col cols="6" lg="6" md="6">
                                <s-select-definition label="Seleccione Mes" :def="1131" return-object v-model="cboMes"></s-select-definition>
                            </v-col>
                            <v-col cols="6" lg="6" md="6">
                                <s-text label="Ingrese año" number v-model="txtYear"></s-text>
                            </v-col>
                        </v-row>

                        <v-data-table height="auto" :items="exchangeList" :headers="exchangeConf.headers" dense hide-default-footer :items-per-page="-1" />
                        <v-btn block small elevation="0" style="text-transform: none;" :color="'#5175AF'" dark class="mt-2" @click="initialize">
                            Consultar
                        </v-btn>
                    </v-col>

                    <v-divider vertical></v-divider>

                    <v-col cols="12" lg="4" md="4">
                        <h2>REG COMPRAS (Destino de Adquis.)</h2>
                        <p class="mt-2">
                            Descargar Plantilla
                            <!-- <v-icon @click="openFile({name: 'FORMATO - REG COMPRAS (Destino de Adquis.)'})" style="margin-left: 5px;" color="green darken-1">far fa-file-excel</v-icon> -->
                            <v-btn color="success" class="mt-0" icon x-small @click="openFile({name: 'FORMATO - REG COMPRAS (Destino de Adquis.).xlsx'})">
                                <v-icon>mdi-download</v-icon>
                            </v-btn>
                        </p>
                        <v-row>
                            <v-col cols="12" lg="12" md="12">
                                <s-import-excel :accept="'.xlsx'" @collection="collection($event)" :headers="headersdestinyAdq"></s-import-excel>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-divider vertical></v-divider>

                    <v-col cols="12" lg="4" md="4">
                        <h2>REG COMPRAS - COD</h2>
                        <p class="mt-2">
                            Descargar Plantilla
                            <!-- <v-icon @click="openFile({name: 'FORMATO - REG COMPRAS - COD'})" style="margin-left: 5px;" color="green darken-1">far fa-file-excel</v-icon> -->
                            <v-btn color="success" class="mt-0" icon x-small @click="openFile({name: 'FORMATO - REG COMPRAS - COD.xlsx'})">
                                <v-icon>mdi-download</v-icon>
                            </v-btn>
                        </p>
                        <v-row>
                            <v-col cols="12" lg="12" md="12">
                                <s-import-excel :accept="'.xlsx'" @collection="collectionpurcharse($event)" :headers="headersregpurcharse"></s-import-excel>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-divider horizontal class="mt-6 mb-6"></v-divider>

                <v-row>

                    <v-col cols="12" lg="4" md="4">
                        <h2>PARTIDAS</h2>
                        <p class="mt-2">
                            Descargar Plantilla
                            <!-- <v-icon @click="openFile({name: 'FORMATO - REG COMPRAS - COD'})" style="margin-left: 5px;" color="green darken-1">far fa-file-excel</v-icon> -->
                            <v-btn color="success" class="mt-0" icon x-small @click="openFile({name: 'PARTIDAS.xlsx'})">
                                <v-icon>mdi-download</v-icon>
                            </v-btn>
                        </p>
                        <v-row>
                            <v-col cols="12" lg="12" md="12">
                                <s-select-definition label="Libro" :def="1164" return-object v-model="cboBook"></s-select-definition>
                            </v-col>
                            <v-col cols="12" lg="12" md="12">
                                <s-import-excel :accept="'.xlsx'" @collection="collectionpartidas($event)" :headers="headerspartidas"></s-import-excel>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-divider vertical></v-divider>
                    <v-col cols="12" lg="4" md="4">
                        <h2>CODIFICACION</h2>
                        <p class="mt-2">
                            REP REF Y LOTE NUEVO - Descargar Plantilla
                            <!-- <v-icon @click="openFile({name: 'FORMATO - REG COMPRAS - COD'})" style="margin-left: 5px;" color="green darken-1">far fa-file-excel</v-icon> -->
                            <v-btn color="success" class="mt-0" icon x-small @click="openFile({name: 'FORMATO - CODIFICAR CAMPO REP REF,LOTE NUEVO.xlsx'})">
                                <v-icon>mdi-download</v-icon>
                            </v-btn>
                        </p>
                        <v-row>
                            <v-col cols="12" lg="12" md="12">
                                <s-select-definition label="Libro" :def="1164" return-object v-model="cboBook"></s-select-definition>
                            </v-col>
                            <v-col cols="12" lg="12" md="12">
                                <s-import-excel :accept="'.xlsx'" @collection="collectionpartidas($event)" :headers="headerscodificacion"></s-import-excel>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-divider vertical></v-divider>
                    <v-col cols="12" lg="12" md="12">
                        <h2>CARGA BANCOS</h2>
                        <p class="mt-2">
                            Descargar Plantilla
                            <!-- <v-icon @click="openFile({name: 'FORMATO - REG COMPRAS - COD'})" style="margin-left: 5px;" color="green darken-1">far fa-file-excel</v-icon> -->
                            <v-btn color="success" class="mt-0" icon x-small @click="openFile({name: 'FORMATO - CARGA BANCOS.xlsx'})">
                                <v-icon>mdi-download</v-icon>
                            </v-btn>
                        </p>
                        <v-row>
                            <v-col cols="12" lg="4" md="4">
                            <s-select-definition label="Seleccione Conjunto de datos" :def="1175" return-object v-model="cboDataBase"></s-select-definition>
                            </v-col>
                            <v-col cols="12" lg="12" md="12">
                                <s-import-excel :accept="'.xlsx'" @collection="chargeBanks($event)" :headers="headersCarga"></s-import-excel>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

            </v-card-text>
        </v-card>
    </v-col>

    <v-dialog v-model="processing" :persistent="messageProcessing.length == 0" width="400">
        <v-card color="primary" dark>
            <v-card-text v-if="messageProcessing.length == 0">
                Por favor espere...
                <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
            <v-card-text v-else>
                <div class="text--white pt-4">
                    Error al conectarse con [SUNAT] <br />
                    {{ messageProcessing }}
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</v-row>
</template>

<script>
import _sOtherSupport from "@/services/Security/OtherSupportExactusService.js";
import _sGeneral from "@/services/HelperService.js";

export default {
    data() {
        return {
            processing: false,
            messageProcessing: "",
            loading: false,
            exchangeList: [],
            exchangeConf: {
                headers: [{
                        text: "Fecha",
                        value: "Fecha",
                        sortable: false,
                        align: "center",
                        width: 80
                    },
                    {
                        text: "Compra",
                        value: "Compra",
                        sortable: false,
                        align: "end",
                    },
                    {
                        text: "Venta",
                        value: "Venta",
                        sortable: false,
                        align: "end",
                    },
                ],
            },
            exchangeDate: {},
            txtYear: this.$fun.getYear(),
            cboMes: null,

            cboDataBase: null,
            cboBusiness: null,
            cboBook: null,

            headersdestinyAdq: [{
                    text: "Asiento",
                    value: "ASIENTO",
                    column: "ASIENTO"
                },
                {
                    text: "Destino Correcto",
                    value: "DESTINO_CORRECTO",
                    column: "DESTINO_CORRECTO"
                },
            ],

            headersregpurcharse: [{
                    text: "Asiento",
                    value: "ASIENTO",
                    column: "ASIENTO"
                },
                {
                    text: "Bien/Servicio Correcto",
                    value: "BIEN_SERVICIO_CORRECTO",
                    column: "BIEN_SERVICIO_CORRECTO"
                },
            ],

            headerspartidas: [{
                    text: "Asiento",
                    value: "ASIENTO",
                    column: "ASIENTO"
                },
                {
                    text: "Consecutivo",
                    value: "CONSECUTIVO",
                    column: "CONSECUTIVO"
                },
                {
                    text: "Partida Correcta",
                    value: "PARTIDA_CORRECTA",
                    column: "PARTIDA_CORRECTA"
                },
            ],

            headerscodificacion: [{
                    text: "Asiento",
                    value: "ASIENTO",
                    column: "ASIENTO"
                },
                {
                    text: "Consecutivo",
                    value: "CONSECUTIVO",
                    column: "CONSECUTIVO",
                    width: 2
                },
                {
                    text: "Ref",
                    value: "REP_REF",
                    column: "REP_REF"
                },
                {
                    text: "Lote Nuevos",
                    value: "LOTE_NUEVOS",
                    column: "LOTE_NUEVOS"
                },
            ],
            headersCarga: [{
                    text: "Fecha Documento",
                    value: "FECHA_DOCUMENTO",
                    column: "FECHA_DOCUMENTO",
                    date: true,
                },
                {
                    text: "NIT",
                    value: "PROVEEDOR",
                    column: "PROVEEDOR"
                },
                {
                    text: "Documento",
                    value: "DOCUMENTO",
                    column: "DOCUMENTO"
                },
                {
                    text: "Tipo Documento",
                    value: "TIPO",
                    column: "TIPO"
                },
                {
                    text: "Centro de Costo",
                    value: "CENTRO_COSTO",
                    column: "CENTRO_COSTO"
                },
                {
                    text: "Cuenta Contable",
                    value: "CUENTA_CONTABLE",
                    column: "CUENTA_CONTABLE"
                },
                {
                    text: "Paquete",
                    value: "PAQUETE",
                    column: "PAQUETE"
                },
                {
                    text: "Tipo de asiento",
                    value: "TIPO_DE_ASIENTO",
                    column: "TIPO_DE_ASIENTO"
                },
            ]

        }
    },
    methods: {

        openFile(url) {
            if (url == null) return;
            _sGeneral.download(url.name, this.$fun.getUserID(), 3).then((r) => {
                this.$fun.download(r.data, url.name);
            });
        },

        initialize() {
            this.loadExchangeRate();
        },

        //Tipo de Cambio
        loadExchangeRate() {
            this.loading = true;
            this.messageProcessing = ""
            this.processing = true
            _sOtherSupport.getExchangeRateSunat(this.txtYear, this.cboMes.DedHelper, this.$fun.getUserID()).then((r) => {
                    this.exchangeList = []
                    this.exchangeList = r.data
                    this.processing = false;
                    this.loading = false;

                    console.log(r.data)
                },
                (e) => {
                    this.messageProcessing = e.response.data.Message;
                    this.processing = true;
                });
        },

        exportExcel() {

            if (this.exchangeList.length == 0) {
                this.$fun.alert("No existen registros para exportar", "warning")
                return
            }

            this.messageProcessing = ""
            this.processing = true
            _sOtherSupport
                .excel(this.exchangeList, this.$fun.getUserID())
                .then(
                    (r) => {
                        if (r.status == 200) {

                            this.$fun.downloadFile(
                                r.data,
                                this.$const.TypeFile.EXCEL,
                                "Tipo Cambio SUNAT"
                            );
                            this.processing = false;
                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },

        isValidated(collection) {
            var isValid = false
            if (this.cboBusiness == null) {
                this.$fun.alert("Seleccione una empresa", "warning")
                isValid = true
            }

            if (collection == null || collection.length == 0) {
                this.$fun.alert("Seleccione un archivo", "warning")
                isValid = true
            }

            return isValid
        },

        collection(collection) {

            var isValid = true;

            if (this.isValidated(collection)) {
                return
            }

            this.$fun.alert("Seguro de cargar datos para: " + this.cboBusiness.DedDescription + " ?", "question").then((r) => {
                if (r.value) {

                    this.loading = true;
                    this.messageProcessing = ""
                    this.processing = true

                    collection.map((item) => {
                        item.UsrCreateID = this.$fun.getUserID();
                        item.SecStatus = 1;
                        item.EMPRESA = this.cboBusiness.DedHelper

                        if (item.ASIENTO == '' || item.ASIENTO == null || item.ASIENTO == undefined) {
                            this.$fun.alert("Error, unos de los registros, no tiene Nro. de Asiento. Por favor verifique.", "error")
                            isValid = false;
                            return;
                        }

                        if (item.DESTINO_CORRECTO == '' || item.DESTINO_CORRECTO == null || item.DESTINO_CORRECTO == undefined) {
                            this.$fun.alert("Error, unos de los registros, no tiene Destino Correcto. Por favor verifique.", "error")
                            isValid = false;
                            return;
                        }
                    });

                    if (isValid) {
                        console.log("savePurcharseAdquisision", collection)

                        _sOtherSupport.savePurcharseAdquisision(collection, this.$fun.getUserID()).then(resp => {
                            this.$fun.alert("Operacion completada correctamente", "success")
                            this.processing = false
                            this.loading = false;
                        }, (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = true;
                        });
                    }

                }
            });
        },

        collectionpurcharse(collection) {
            if (this.isValidated(collection)) {
                return
            }

            var isValid = true;
            this.$fun.alert("Seguro de cargar datos para: " + this.cboBusiness.DedDescription + " ?", "question").then((r) => {
                if (r.value) {

                    this.loading = true;
                    this.messageProcessing = ""
                    this.processing = true

                    collection.map((item) => {
                        item.UsrCreateID = this.$fun.getUserID();
                        item.SecStatus = 1;
                        item.EMPRESA = this.cboBusiness.DedHelper

                        if (item.ASIENTO == '' || item.ASIENTO == null || item.ASIENTO == undefined) {
                            this.$fun.alert("Error, unos de los registros, no tiene Nro. de Asiento. Por favor verifique.", "error")
                            isValid = false;
                            return;
                        }

                        if (item.BIEN_SERVICIO_CORRECTO == '' || item.BIEN_SERVICIO_CORRECTO == null || item.BIEN_SERVICIO_CORRECTO == undefined) {
                            this.$fun.alert("Error, unos de los registros, no tiene Bien/Servicio Correcto. Por favor verifique.", "error")
                            isValid = false;
                            return;
                        }
                    });

                    if (isValid) {

                        _sOtherSupport.savePurcharseCod(collection, this.$fun.getUserID()).then(resp => {
                            this.$fun.alert("Operacion completada correctamente", "success")
                            this.processing = false
                            this.loading = false;
                        }, (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = true;
                        });
                    }

                }
            });
        },

        collectionpartidas(collection) {
            if (this.isValidated(collection)) {
                return
            }

            if (this.cboBook == null) {
                return
            }
            if (this.cboBook.DedHelper == null) {
                return
            }

            var isValid = true;
            this.$fun.alert("Seguro de cargar datos para: " + this.cboBusiness.DedDescription + " ?", "question").then((r) => {
                if (r.value) {

                    this.loading = true;
                    this.messageProcessing = ""
                    this.processing = true

                    collection.map((item) => {

                        item.UsrCreateID = this.$fun.getUserID();
                        item.SecStatus = 1;
                        item.EMPRESA = this.cboBusiness.DedHelper
                        item.LIBRO = this.cboBook.DedHelper

                        if (item.ASIENTO == '' || item.ASIENTO == null || item.ASIENTO == undefined) {
                            this.$fun.alert("Error, unos de los registros, no tiene Nro. de Asiento. Por favor verifique.", "error")
                            isValid = false;
                            return;
                        }

                        if (item.CONSECUTIVO == '' || item.CONSECUTIVO == null || item.CONSECUTIVO == undefined) {
                            this.$fun.alert("Error, unos de los registros, no tiene Consecutivo Correcto. Por favor verifique.", "error")
                            isValid = false;
                            return;
                        }

                        if (item.PARTIDA_CORRECTA == '' || item.PARTIDA_CORRECTA == null || item.PARTIDA_CORRECTA == undefined) {
                            this.$fun.alert("Error, unos de los registros, no tiene Partida Correcta. Por favor verifique.", "error")
                            isValid = false;
                            return;
                        }
                    });

                    if (isValid) {
                        console.log("savePartidas", collection)

                        _sOtherSupport.savePartidas(collection, this.$fun.getUserID()).then(resp => {

                            this.$fun.alert("Operacion completada correctamente", "success")
                            this.processing = false
                            this.loading = false;
                        }, (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = true;
                        });
                    }

                }
            });
        },

        chargeBanks(collection) {
            if (this.isValidated(collection)) {
                return
            }

            this.$fun.alert("Seguro de cargar", "question", false).then((r) => {
                if (r.value) {
                    this.loading = true;
                    this.messageProcessing = ""
                    this.processing = true

                    collection.map((item) => {
                        item.FECHA_DOCUMENTO = this.$moment(new Date((item.FECHA_DOCUMENTO - (25567 + 1)) * 86400 * 1000)).format("YYYY-MM-DD");
                        item.DATABASE = this.cboDataBase.DedHelper,
                        item.EMPRESA = this.cboBusiness.DedHelper
                    });

                    /* console.log("chargeBanks", collection)                */

                    _sOtherSupport.chargeBanks(collection, this.$fun.getUserID()).then(resp => {
                        if (resp.status == 200) {
                            console.log(resp.data)
                            this.excelBanks(resp.data)
                            this.$fun.alert("Operacion completada correctamente", "success")
                            this.processing = false
                            this.loading = false;
                        }

                    }, (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    });

                }
            });
        },

        excelBanks(list) {
            _sOtherSupport.excelBanks(list, this.$fun.getUserID()).then((r) => {
                if (r.status == 200) {
                    this.$fun.downloadFile(r.data, this.$const.TypeFile.EXCEL, "REPORTE CARGA");
                    this.processing = false
                }

            });
        },

        deleteAsientos() {

            if (this.cboBusiness == null) {
                return;
            }

            var filter = {}
            filter = {
                Empresa: this.cboBusiness.DedDescription
            }

            this.$fun.alert("Seguro de continuar", "question").then(val => {
                if (val.value) {
                    this.loading = true;
                    this.messageProcessing = ""
                    this.processing = true
                    _sOtherSupport.deleteAsientos(filter, this.$fun.getUserID()).then(resp => {
                        if (resp.status == 200) {
                            this.$fun.alert("Operacion completada correctamente", "success")
                            this.processing = false
                            this.loading = false;
                        }

                    }, (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    });
                }
            })

        }
    },
};
</script>
