import Service from "../Service";

const resource = "othersupportexactus/";

export default {

    savePurcharseAdquisision(obj, requestID) {
        return Service.post(resource + "saveregpurcharse", obj, {
            params: { requestID: requestID },
        });
    },

    savePurcharseCod(obj, requestID) {
        return Service.post(resource + "saveregpurcharsecod", obj, {
            params: { requestID: requestID },
        });
    },

    savePartidas(obj, requestID) {
        return Service.post(resource + "savepartidas", obj, {
            params: { requestID: requestID },
        });
    },

    getExchangeRateSunat(anio, mes, requestID) {
        return Service.post(
            "changerate/" + "getRate", {}, {
                params: { anio, mes, requestID },
            }
        );
    },


    deleteAsientos(obj, requestID) {
        return Service.post(resource + "deleteasientos", obj, {
            params: { requestID: requestID },
        });
    },

    excel(obj, requestID) {
        return Service.post("changerate/" + "excel", obj, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID: requestID },
        });
    },

    chargeBanks(obj, requestID) {
        return Service.post(resource + "chargebanks", obj, {
            params: { requestID: requestID },
        });
    },

    excelBanks(obj, requestID) {
        return Service.post(resource + "excelbanks", obj, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID: requestID },
        });
    },





};